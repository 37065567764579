<template>
    <div v-if="maxImageWidth>0">
        <div :style="{'height': maxImageHeight + 'px', 'width': maxImageWidth + 'px'}">
            <img :src="this.$imageFilePath + imageFilePath" onerror="this.src='/img/ZAbrands/ZAsymbol.svg'" style="max-height:100%; max-width:100%;" />
        </div>
    </div>
    <div v-else>
        <div :style="{'height': '100%', 'width': '100%'}">
            <img :src="this.$imageFilePath + imageFilePath" onerror="this.src='/img/ZAbrands/ZAsymbol.svg'" style="max-height:100%; max-width:100%;" />
        </div>
    </div>

    <!--<div v-if="imageFilePath === null" :style="{'height': maxImageHeight + 'px', 'width': maxImageWidth + 'px', 'text-align': 'center'}">
        <img src="/img/ZAbrands/ZAsymbol.svg" style="max-height:100%; max-width:100%;" />
    </div>
    <div v-else-if="imageFilePath === ''" :style="{'height': maxImageHeight + 'px', 'width': maxImageWidth + 'px', 'text-align': 'center'}">
        <img src="/img/ZAbrands/ZAsymbol.svg" style="max-height:100%; max-width:100%;" />
    </div>
    <div v-else :style="{'height': maxImageHeight + 'px', 'width': maxImageWidth + 'px', 'text-align': 'center'}">
        <img :src="this.$imageFilePath + imageFilePath" style="max-height:100%; max-width:100%;" />
    </div>-->

</template>

<script>
    export default {
        name: 'Images',
        props: {
            imageFilePath: String,
            maxImageHeight: Number,
            maxImageWidth: Number,
        },
    }
</script>
