<template>
    <div class="ZAfont_family" style="color:red; line-height:100%; margin-top:5px">
        <div v-if="validationResult.invalidityReason == this.$invalidityReasons.notSet">{{$t('3173')}}</div>
        <div v-if="validationResult.invalidityReason == this.$invalidityReasons.noNumber">{{$t('3169')}}</div>
        <div v-else-if="validationResult.invalidityReason == this.$invalidityReasons.noInteger">{{$t('3170')}}</div>
        <div v-else-if="validationResult.invalidityReason == this.$invalidityReasons.containsUnwantedCharacters">{{$t('3352')}} <span class="my_font-weight_600">" , ; < > \</span></div>
        <div v-else-if="validationResult.invalidityReason == this.$invalidityReasons.characterLengthExceeded">{{$t('3353')}}</div>
        <div v-else-if="validationResult.invalidityReason == this.$invalidityReasons.permissibleNumberOfDecimalPlacesExceeded">{{$t('3236', {0: validationResult.permissibleNumberOfDecimalPlaces})}}</div>
        <div v-else-if="validationResult.invalidityReason == this.$invalidityReasons.outOfPermissibleValueRange">
            <div v-if="((validationResult.permissibleValueRange.minimum.value!=null)&&(validationResult.permissibleValueRange.maximum.value!=null)&&(validationResult.permissibleValueRange.minimum.operator==this.$operators.greaterThanOrEqualTo)&&(validationResult.permissibleValueRange.maximum.operator==this.$operators.lessThanOrEqualTo))">{{$t('3243', {0: validationResult.permissibleValueRange.minimum.value, 1: validationResult.permissibleValueRange.maximum.value})}}</div>
            <div v-else-if="((validationResult.permissibleValueRange.minimum.value!=null)&&(validationResult.permissibleValueRange.maximum.value!=null)&&(validationResult.permissibleValueRange.minimum.operator==this.$operators.greaterThanOrEqualTo)&&(validationResult.permissibleValueRange.maximum.operator==this.$operators.lessThan))">{{$t('3244', {0: validationResult.permissibleValueRange.minimum.value, 1: validationResult.permissibleValueRange.maximum.value})}}</div>
            <div v-else-if="((validationResult.permissibleValueRange.minimum.value!=null)&&(validationResult.permissibleValueRange.maximum.value!=null)&&(validationResult.permissibleValueRange.minimum.operator==this.$operators.greaterThan)&&(validationResult.permissibleValueRange.maximum.operator==this.$operators.lessThanOrEqualTo))">{{$t('3245', {0: validationResult.permissibleValueRange.minimum.value, 1: validationResult.permissibleValueRange.maximum.value})}}</div>
            <div v-else-if="((validationResult.permissibleValueRange.minimum.value!=null)&&(validationResult.permissibleValueRange.maximum.value!=null)&&(validationResult.permissibleValueRange.minimum.operator==this.$operators.greaterThan)&&(validationResult.permissibleValueRange.maximum.operator==this.$operators.lessThan))">{{$t('3246', {0: validationResult.permissibleValueRange.minimum.value, 1: validationResult.permissibleValueRange.maximum.value})}}</div>
        </div>
        <div v-else-if="validationResult.invalidityReason == this.$invalidityReasons.minimumValueGreaterThanMaximumValue">{{$t('3247')}}</div>
    </div>
</template>

<script>
    export default {
        name: 'InvalidityReasons',
        props: {
            validationResult: Object,
        },
    }

</script>